<template>
  <my-echarts :option="option" :style="{ width: props.width, height: props.height }"></my-echarts>
</template>

<script setup>
import { ref, watch, defineExpose } from "vue";
const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
  width: {
    type: String,
    default: "100%",
  },
  height: {
    type: String,
    default: "100%",
  },
  tooltipList: {
    type: Array,
    default: () => [
      { label: '数量', unit: '个'}, 
      { label: '占比', unit: '%' }
    ],
  },
  radius: {
    type: Array,
    default: () => ['60%', '92%']
  },
  center: {
    type: Array,
    default: () => (window.innerWidth < 1920 ? ['50%', '50%'] : ['30%', '50%'])
  },
  showLegend: {
    type: Boolean,
    default: () => (window.innerWidth < 1920 ? false : true)
  },
  colorList: {
    type: Array,
    default: () => ['#197BF8', '#0EC581', '#647697', '#EFBA1C', '#EF581C', '#2CC5FF'],
  },
  silent: {
    type: Boolean,
    default: false
  }
});
const option = ref({});
const initData = () => {
  option.value = {
    color: props.colorList,
    dataset: {
      dimensions: ['product', 'count', 'rate'],
      source: props.dataSource,
    },
    tooltip: {
      trigger: 'item',
      confine: true,
      formatter: function(params) {
        return `${params.value.product}<br>${ params.marker + props.tooltipList[0].label}：${ params.value.count + props.tooltipList[0].unit } 
        <br>${ params.marker + props.tooltipList[1].label}：${ params.value.rate + props.tooltipList[1].unit }`
      }
    },
    legend: {
      show: props.showLegend,
      top: 'middle',
      left: '60%',
      icon: 'circle',
      orient: 'vertical'
    },
    series: [
    {
      name: ' ',
      type: 'pie',
      silent: props.silent,
      radius: props.radius,
      center: props.center,
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'inside',
        fontSize: 10,
        formatter(params){
          return params.data.rate + '%' + '\n' + params.data.product  
        },
        color: '#fff'
        // formatter: '{d}%', // 显示百分比
      },
      labelLine: {
        show: false
      }
    }
    ],
  };
};
watch(() => props.dataSource, () => 
  { 
    initData()
  },
  { immediate: true, deep: true }
);
defineExpose({
  option,
});
</script>