<template>
  <div class="echart" ref="chartDom"></div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps, watch } from "vue";
import * as echarts from 'echarts';

const chartDom = ref()
let myChart = null;
const chart = ref(null)
const props = defineProps({
  option: Object,
})

const resizeHandler = () => {
  myChart.resize();
}

const reDrawer = () => {
  myChart.clear();
  chart.value = myChart = echarts.init(chartDom.value)
  myChart.setOption(props.option, true);
}

//设置防抖，保证无论拖动窗口大小，只执行一次获取浏览器宽高的方法
const debounce = (fun, delay) => {
  let timer;
  return function () {
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(() => {
          fun();
      }, delay);
  }
};
const cancalDebounce = debounce(resizeHandler, 500);

onMounted(() => {
  //配置为 svg 形式，预防页面缩放而出现模糊问题；图表过于复杂时建议使用 Canvas
  //myChart = echarts.init(chartDom.value, null, {renderer:'svg'})
  chart.value = myChart = echarts.init(chartDom.value)
  myChart.setOption(props.option, true);
  window.addEventListener('resize', cancalDebounce);
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', cancalDebounce)
  myChart.dispose()
})

watch(() => props.option, () => {
  myChart.setOption(props.option, true);
}, { deep: true })

defineExpose({
  reDrawer,
  chart
})
</script>

<style scoped>
.echart {
  width: 100%;
  height: 100%
}
</style>